import React from 'react';
import Card from './Card';

interface FourOhFourProps {
  path?: string;
  default?: boolean;
}

const FourOhFour = (props: FourOhFourProps) => {
  return (
    <Card>
      <section className="text-center">
        <h1 className="text-primary font-bold text-6xl">404</h1>
        <p className="text-gray-800 text-xl mt-10">This page does not exist</p>
      </section>
    </Card>
  );
};

export default FourOhFour;
