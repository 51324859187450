import React from 'react';
import Card from '../components/common/Card';
import ContentContainer from '../components/common/ContentContainer';
import FourOhFour from '../components/common/FourOhFour';

import Layout from '../components/Layout';
import SEO from '../components/Seo';

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <ContentContainer>
      <FourOhFour />
    </ContentContainer>
  </Layout>
);

export default NotFoundPage;
